import type { KsChange } from '@knapsack/types';
import { appApiGql } from '@/services/app-api-client';

export async function saveDataChanges({
  changes,
  instanceId,
}: {
  changes: KsChange[];
  instanceId: string;
}): Promise<void> {
  const dataChanges = changes.map((change) => ({
    // Convert KsChange[] to DataChange[]
    patches: change.patches.map((patch) => {
      // Ensure that all keys in patch are strings before returning patch
      return Object.fromEntries(
        Object.entries(patch).map(([key, value]) => [key, value]),
      );
    }),
    // Convert KsChange[] to DataChange[]
    inversePatches: change.inversePatches.map((patch) => {
      // Ensure that all keys in patch are strings before returning patch
      return Object.fromEntries(
        Object.entries(patch).map(([key, value]) => [key, value]),
      );
    }),
  }));

  const { updateBranchContent } = await appApiGql.updateBranchContent({
    instanceId,
    changes: dataChanges,
  });
  const dataChangeId = updateBranchContent?.dataChangeId;
  if (!dataChangeId) {
    throw new Error(`Could not save data changes`);
  }
}
