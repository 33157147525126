import type { AppSection } from './app-sections';

/**
 * The roles here are duplicates of the roles in the database.
 * It needs to be here, in the types package, so it can be used in all packages.
 * If we pull it in from `@knapsack/hasura-gql-client`, which is the source, we get a dependency cycle. So we do it this way.
 * If the roles change in the database, we need to change them here too. We will get a build error if we don't (see code in `@knapsack/hasura-gql-client`).
 */
export type Role =
  | 'ADMIN'
  | 'CONTRIBUTOR'
  | 'CONSUMER'
  | 'VIEWER'
  | 'ANONYMOUS'
  /**
   * These are not used, but exist in the DB
   * @todo: remove unused roles from database
   * @link https://linear.app/knapsack/issue/KSP-5991/remove-unused-roles-from-database
   */
  | 'EDITOR'
  | 'MANAGER';

export type RolePermissions = {
  canManageUsers: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canPublish: boolean;
  canView: boolean;
};

export type RoleSectionAccess = Record<
  AppSection,
  {
    canView: boolean;
    canEdit: boolean;
  }
>;

export type RoleInfo = {
  id: Role;
  title: string;
  hierarchy: number;
  permissions: RolePermissions;
  sectionAccess: RoleSectionAccess;
};

export const rolesById: Record<Role, RoleInfo> = {
  ADMIN: {
    id: 'ADMIN',
    title: 'Admin',
    hierarchy: 4,
    permissions: {
      canManageUsers: true,
      canDelete: true,
      canEdit: true,
      canPublish: true,
      canView: true,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: true,
      },
      DOCUMENT: {
        canEdit: true,
        canView: true,
      },
      DESIGN: {
        canEdit: true,
        canView: true,
      },
      DEVELOP: {
        canEdit: true,
        canView: true,
      },
      PROTOTYPE: {
        canEdit: true,
        canView: true,
      },
      SETTINGS: {
        canEdit: true,
        canView: true,
      },
    },
  },
  CONTRIBUTOR: {
    id: 'CONTRIBUTOR',
    title: 'Contributor',
    hierarchy: 3,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: true,
      canPublish: false,
      canView: true,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: true,
      },
      DOCUMENT: {
        canEdit: true,
        canView: true,
      },
      DESIGN: {
        canEdit: true,
        canView: true,
      },
      DEVELOP: {
        canEdit: true,
        canView: true,
      },
      PROTOTYPE: {
        canEdit: true,
        canView: true,
      },
      SETTINGS: {
        canEdit: true,
        canView: true,
      },
    },
  },
  CONSUMER: {
    id: 'CONSUMER',
    title: 'Consumer',
    hierarchy: 2,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: true,
      canPublish: false,
      canView: true,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: true,
      },
      DOCUMENT: {
        canEdit: false,
        canView: true,
      },
      DESIGN: {
        canEdit: false,
        canView: false,
      },
      DEVELOP: {
        canEdit: false,
        canView: false,
      },
      PROTOTYPE: {
        canEdit: true,
        canView: true,
      },
      SETTINGS: {
        canEdit: false,
        canView: false,
      },
    },
  },
  VIEWER: {
    id: 'VIEWER',
    title: 'Viewer',
    hierarchy: 1,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: false,
      canPublish: false,
      canView: true,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: true,
      },
      DOCUMENT: {
        canEdit: false,
        canView: true,
      },
      DESIGN: {
        canEdit: false,
        canView: false,
      },
      DEVELOP: {
        canEdit: false,
        canView: false,
      },
      PROTOTYPE: {
        canEdit: false,
        canView: false,
      },
      SETTINGS: {
        canEdit: false,
        canView: false,
      },
    },
  },
  ANONYMOUS: {
    id: 'ANONYMOUS',
    title: 'Anonymous',
    hierarchy: 0,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: false,
      canPublish: false,
      canView: true,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: true,
      },
      DOCUMENT: {
        canEdit: false,
        canView: true,
      },
      DESIGN: {
        canEdit: false,
        canView: false,
      },
      DEVELOP: {
        canEdit: false,
        canView: false,
      },
      PROTOTYPE: {
        canEdit: false,
        canView: false,
      },
      SETTINGS: {
        canEdit: false,
        canView: false,
      },
    },
  },
  /**
   * These are not used, but exist in the DB
   * @todo: remove unused roles from database
   * @link https://linear.app/knapsack/issue/KSP-5991/remove-unused-roles-from-database
   */
  EDITOR: {
    id: 'EDITOR',
    title: 'Editor',
    hierarchy: 0,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: false,
      canPublish: false,
      canView: false,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: false,
      },
      DOCUMENT: {
        canEdit: false,
        canView: false,
      },
      DESIGN: {
        canEdit: false,
        canView: false,
      },
      DEVELOP: {
        canEdit: false,
        canView: false,
      },
      PROTOTYPE: {
        canEdit: false,
        canView: false,
      },
      SETTINGS: {
        canEdit: false,
        canView: false,
      },
    },
  },
  MANAGER: {
    id: 'MANAGER',
    title: 'Manager',
    hierarchy: 0,
    permissions: {
      canManageUsers: false,
      canDelete: false,
      canEdit: false,
      canPublish: false,
      canView: false,
    },
    sectionAccess: {
      ROOT: {
        canEdit: false,
        canView: false,
      },
      DOCUMENT: {
        canEdit: false,
        canView: false,
      },
      DESIGN: {
        canEdit: false,
        canView: false,
      },
      DEVELOP: {
        canEdit: false,
        canView: false,
      },
      PROTOTYPE: {
        canEdit: false,
        canView: false,
      },
      SETTINGS: {
        canEdit: false,
        canView: false,
      },
    },
  },
};

export const roles = Object.values(rolesById);
