export const appSectionsById = {
  ROOT: 'ROOT',
  DESIGN: 'Design',
  DEVELOP: 'Develop',
  DOCUMENT: 'Documentation',
  PROTOTYPE: 'Prototype',
  SETTINGS: 'Settings',
} as const;
export const appSections = Object.values(appSectionsById);
export type AppSection = keyof typeof appSectionsById;
