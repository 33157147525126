import { send, StateNodeConfig } from 'xstate';
import { assign } from '@xstate/immer';
import { getKsClaims } from '@knapsack/core';
import { hasuraGql } from '@/services/hasura-client';
import { PromiseResult } from '@/utils/type-utils';
import {
  login,
  auth0,
  type Auth0IdToken,
  type Auth0User,
} from '@/services/auth0';
import { isBrowser } from '@knapsack/utils';
import { isCypress, type LogoutRedirectQueryParam } from '@/utils/constants';
import { createToast } from '@knapsack/toby';
import {
  clearUser,
  setGlobalContextProperty,
  setUserId,
} from '@/services/logger';
import { uiMachine } from '../../ui/ui.xstate';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';
import { getUserRoleOverride } from '../../../../utils/user-override-utils';
import type { UiEvents } from '../../ui/types';
import type { AppClientDataEvents } from '../../app-client-data';
import { userChangesBroadcastChannel } from './user-changes-broadcast-channel';

/**
 * Fetch user from db
 */
async function loadUser({ userId, email }: { userId: string; email: string }) {
  const today = new Date().toISOString();
  if (!userId) throw new Error(`Load User missing userId`);
  if (!email) throw new Error(`Load User missing email`);
  try {
    const res = await hasuraGql.UpdateAndGetUser({
      email,
      userId,
      today,
    });
    return res.user;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Loading User Details failed. userId probably does not exist in our DB: "${userId}", or auth token is bad.`,
    );
  }
}

function normalizeUser({
  auth0User,
  idToken,
}: {
  auth0User: Auth0User;
  idToken: Auth0IdToken;
}): AppContext['user'] {
  const { email, email_verified: emailVerified } = auth0User;
  const { isSuperAdmin, siteRoleMap, userId, getSiteRole } =
    getKsClaims(idToken);
  const roleOverride = getUserRoleOverride();
  return {
    userId,
    email,
    emailVerified,
    isSuperAdmin,
    membershipSiteIds: Object.keys(siteRoleMap ?? {}).sort(),
    getSiteRole:
      isSuperAdmin && roleOverride ? () => roleOverride : getSiteRole,
  };
}

export const userStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['user'],
  AppEvents
> = {
  id: 'user',
  initial: 'unknown',
  strict: true,
  states: {
    unknown: {
      invoke: createInvokablePromise<{ user?: AppContext['user'] }>({
        id: 'authPromiseChecker',
        src: async () => {
          // Just in case
          if (!isBrowser) {
            // it's ok, we'll just stay in the unknown state
            // might be b/c of SSR or Unit Tests
            return {};
          }

          if (isCypress()) {
            const { getCypressSessionConfig } = await import(
              '@/domains/users/utils/cypress-session-config'
            );
            const {
              user: { userRole },
            } = await getCypressSessionConfig();

            if (userRole === 'ANONYMOUS') return {};

            return {
              user: {
                userId: `CYPRESS_${userRole}_ID`,
                email: `CYPRESS_${userRole}@KNAPSACK.CLOUD`,
                emailVerified: true,
                isSuperAdmin: false,
                // having more than one so when we visit `/` we do not get redirected to only siteId in array
                membershipSiteIds: ['ks-sandbox', 'ks-test'],
                // note that this is only going to work for `ks-sandbox` site
                getSiteRole: (siteId) =>
                  siteId === 'ks-sandbox' ? userRole : 'ANONYMOUS',
              },
            };
          }

          await auth0.checkSession();
          // actually faster to run these two promises serially vs in parallel
          // because they cache results instead of promises sadly
          const auth0User = await auth0.getUser();
          if (!auth0User) return {};
          const idToken = await auth0.getIdTokenClaims();
          return { user: normalizeUser({ auth0User, idToken }) };
        },
        onDone: [
          {
            cond: (_, event) => !!event.data?.user,
            target: 'loggedIn',
            actions: assign((ctx, { data }) => {
              ctx.user = data.user;
            }),
          },
          { target: 'loggedOut' },
        ],
        onErrorTarget: 'loggedOut',
        onErrorActions: [
          assign((ctx, { data: error }) => {
            ctx.userAuthError = {
              title: 'User Error',
              description: error.message,
            };
          }),
          {
            type: 'sendUserMessage',
            exec(_, { data: error }) {
              createToast({
                type: 'error',
                title: 'User Error',
                message: error.message,
              });
            },
          },
        ],
      }),
    },
    loggedOut: {
      invoke: {
        id: 'userChangesBroadcastChannel.loggedOut',
        src: () => () => {
          if (!isBrowser) return () => {};
          userChangesBroadcastChannel.postMessage({
            type: 'broadcast.user.loggedOut',
          });
          // returns a cleanup function that will remove the listener
          return userChangesBroadcastChannel.addMessageListener(
            'broadcast.user.loggedIn',
            () => window.location.reload(),
          );
        },
      },
      on: {
        'user.signIn': {
          actions: (_, { connection }) => {
            if (isCypress()) {
              throw new Error('Cypress login not supported');
            }
            // performs a hard navigation page load
            login({ connection });
          },
        },
      },
    },
    loggedIn: {
      initial: 'loadingDetails',
      entry: [
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: true,
          }),
          { to: uiMachine.id },
        ),
        (ctx) => setUserId(ctx.user.userId),
      ],
      exit: [
        assign((ctx) => {
          ctx.user = null;
        }),
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: false,
          }),
          { to: uiMachine.id },
        ),
        () => {
          clearUser();
          setGlobalContextProperty('knapsackMeta', undefined);
        },
        send((): AppClientDataEvents => ({ type: 'user.signedOut' }), {
          to: APP_SUB_MACHINE_IDS.appClientData,
        }),
        {
          type: 'clearing Apollo cache',
          exec: () =>
            import('@/services/util-apollo-graphql.client').then(
              ({ resetApolloClientStore }) => resetApolloClientStore(),
            ),
        },
      ],
      on: {
        'user.signOut': '.loggingOut',
        'user.infoChanged': {
          actions: assign((ctx, { info }) => {
            ctx.user.info = info;
          }),
        },
      },
      invoke: {
        id: 'authWatcher',
        src: () => (sendEvent) => {
          if (isCypress()) return () => {};
          if (!isBrowser) return () => {};
          userChangesBroadcastChannel.postMessage({
            type: 'broadcast.user.loggedIn',
          });
          const removeListener = userChangesBroadcastChannel.addMessageListener(
            'broadcast.user.loggedOut',
            () => window.location.reload(),
          );

          const check = () => {
            auth0.getTokenSilently().catch((e) => {
              console.warn(
                `Error getting token silently, signing out. ${e.message}`,
              );
              userChangesBroadcastChannel.postMessage({
                type: 'broadcast.user.loggedOut',
              });
              sendEvent({ type: 'user.signOut' });
            });
          };
          check();
          const intervalId = setInterval(check, 60_000);
          return () => {
            clearInterval(intervalId);
            removeListener();
          };
        },
      },
      states: {
        loggingOut: {
          invoke: createInvokablePromise<void>({
            id: 'loggingOut',
            src: async () => {
              if (isCypress()) {
                throw new Error('Cypress logout not supported');
              }
              const u = new URL(window.location.origin);
              const paths = window.location.pathname.split('/');
              // getting the siteId from the pathname instead of context b/c we might be on a "You don't have access to this site" page with a logout button - we want them to end up back on that same page so they can try a different login
              const siteId = paths[1] === 'site' ? paths[2] : undefined;
              if (siteId) {
                // see `middleware.ts` for where this is used to ensure users end up on their workspace root instead of the default app home
                // Auth0 has restrictions on where users can be redirected after logout, but it's ok to use a query params to store the intended destination
                u.searchParams.set(
                  'ks-logout-redirect' satisfies LogoutRedirectQueryParam,
                  `/site/${siteId}/${paths[3]}`,
                );
              }
              await auth0.logout({
                logoutParams: {
                  returnTo: u.toString(),
                },
              });
            },
            onDoneTarget: '#app.user.loggedOut',
            onErrorTarget: '#app.user.loggedOut',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, { data: error }) {
                  createToast({
                    type: 'error',
                    title: 'Error logging out',
                    message: error.message,
                  });
                },
              },
            ],
          }),
        },
        loadingDetails: {
          invoke: createInvokablePromise<PromiseResult<typeof loadUser>>({
            id: 'loadUserInfo',
            src: async (ctx) => {
              if (isCypress()) {
                return {
                  // having this set avoids the modal asking to set responsibility
                  responsibilityId: 'OTHER',
                  displayName: `Cypress ${ctx.user.getSiteRole('ks-sandbox')}`,
                };
              }
              return loadUser({
                userId: ctx.user?.userId,
                email: ctx.user?.email,
              });
            },
            onDoneTarget: 'loaded',
            onDoneAssignContext({ ctx, data }) {
              ctx.user.info = data;
            },
            onErrorTarget: 'loadingError',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, event) {
                  createToast({
                    type: 'error',
                    title: 'Error loading user info',
                    message: event.data.message,
                  });
                },
              },
            ],
          }),
        },
        loadingError: {},
        loaded: {},
      },
    },
  },
};
