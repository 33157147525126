import { createHasuraClient } from '@knapsack/hasura-gql-client';
import { captureException } from '@/utils/sentry';
import { getHttpHeaders } from './http-headers-utils';
import { ksUrls } from './ks-urls';

export * from '@knapsack/hasura-gql-client';

export const hasuraGql = createHasuraClient({
  gqlServerUrl: ksUrls.dbGqlEndpoint,
  getHeaders: async () => {
    const baseHeaders = await getHttpHeaders({
      hasuraClientName: 'app-ui--graphql-request',
    });
    const headers: Record<string, string> = {
      ...baseHeaders,
    };
    return headers;
  },
  logError: (error) => captureException(error),
});
