import { Machine, actions } from 'xstate';
import { getXstateUtils } from '@/core/xstate/xstate.utils';
import { assign } from '@xstate/immer';
import {
  Draft,
  applyPatches,
  enablePatches,
  produceWithPatches,
  castDraft,
  isDraft,
} from 'immer';
import { canRoleEdit } from '@knapsack/core';
import { isUnitTesting } from '@/utils/constants';
import {
  getAppClientData,
  submitDataForFileSave,
} from '@/services/app-client.client';
import { saveDataChanges } from '@/domains/branches/api/branches-api';
import { fixAppClientData } from '@knapsack/doctor';
import { KsChange } from '@/types';
import { debounce, now, makeUuid as uuid } from '@knapsack/utils';
import { WS_EVENTS, WebSocketMessages } from '@knapsack/types';
import {
  convertTokenGroupToData,
  getTokenCollectionsCss,
} from '@knapsack/design-token-utils';
import { createToast } from '@knapsack/toby';
import { SET_APP_CLIENT_DATA } from './reducers/shared.xstate';
import { APP_SUB_MACHINE_IDS } from '../app/app.xstate-utils';
import { knapsackGlobal } from '../../../../global';
import {
  AppClientDataCtx,
  AppClientDataEvents,
  AppClientDataState,
  AppClientDataTypestates,
  initialCtx,
} from './types';
import { rootReducer, isTokenEvent, TokenEvents } from './reducers';
import { handleTokenUpdateEvents } from './tokens';
import { DataChangesBroadcastChannel } from './utils/data-changes-broadcast-channel';
import { mergeDeepInImmer } from './reducers/utils/utils.xstate';
import type { Site } from '../app/sub-states/site.xstate-types';

enablePatches();

const {
  createInvokablePromise,
  createXstateHooks: createAppClientDataXstateHooks,
} = getXstateUtils<
  AppClientDataCtx,
  AppClientDataEvents,
  AppClientDataTypestates,
  AppClientDataState
>();

export { createAppClientDataXstateHooks };

const loaderErrorMsg = `Error encountered with the branch you are trying to load might be corrupted or unmergable. Please contact support: help@knapsack.cloud`;

function possibleToSave(site: Site): boolean {
  if (
    site.env.type === 'development' &&
    site.contentSrc.type === 'current-env-server'
  ) {
    return true;
  }
  if (
    site.contentSrc.type === 'cloud-authoring' &&
    site.contentSrc.instance.type === 'branch'
  ) {
    return true;
  }
  return false;
}

function alterActiveCtx({
  ctx,
  recipe,
  event,
}: {
  ctx: AppClientDataCtx;
  event?: AppClientDataEvents;
  recipe: (active: Draft<AppClientDataCtx['active']>) => void;
}): AppClientDataCtx {
  const [nextState, patches, inversePatches] = produceWithPatches(
    ctx.active,
    recipe,
  );
  if (patches.length === 0) return ctx;
  // just telling TypeScript that this is not immutable
  const active = castDraft(nextState);
  const change: KsChange = {
    event,
    patches,
    inversePatches,
    id: uuid(),
    date: now(),
  };
  if (ctx.dataChangesBroadcastChannel) {
    ctx.dataChangesBroadcastChannel.postMessage({
      type: 'data-change',
      dataChange: change,
    });
  }
  return {
    ...ctx,
    active,
    saveStack: [...ctx.saveStack, change],
  };
}

function updateTokensCtx({
  event,
  ctx,
}: {
  event: TokenEvents;
  ctx: AppClientDataCtx;
}): AppClientDataCtx {
  try {
    if (isDraft(ctx)) {
      throw new Error(`Do not call updateTokensCtx called with Immer draft`);
    }
    if (isDraft(ctx.active.tokensSrc)) {
      throw new Error(
        `Do not call updateTokensCtx called with Immer draft (ctx.active.tokensSrc)`,
      );
    }
    const { tokenChanges, tokenData, tokenStyles, tokensSrc } =
      handleTokenUpdateEvents({
        event,
        tokensSrc: ctx.active.tokensSrc,
      });
    return alterActiveCtx({
      event,
      ctx: {
        ...ctx,
        tokenData,
        tokenStyles,
      },
      recipe: (activeCtx) => {
        mergeDeepInImmer({
          target: activeCtx.tokensSrc,
          source: tokensSrc,
          sourceIsPartial: false,
        });
      },
    });
  } catch (error) {
    createToast({
      type: 'error',
      message: `Token error: ${error}`,
    });
  }
}

export const appClientDataMachine = Machine<
  AppClientDataCtx,
  AppClientDataState,
  AppClientDataEvents
>({
  id: APP_SUB_MACHINE_IDS.appClientData,
  context: initialCtx,
  initial: 'empty',
  on: {
    'user.haveBothUserAndSite': {
      actions: assign((ctx, { userId, roleForSite }) => {
        ctx.user = { userId, roleForSite };
      }),
    },
    'user.signedOut': {
      actions: assign((ctx) => {
        ctx.user = null;
      }),
    },
    'appClientData.setLastCommittedDataChangeId': {
      actions: assign((ctx, { id }) => {
        ctx.lastCommittedDataChangeId = id;
      }),
    },
    'appClientData.setLastDataChangeId': {
      actions: assign((ctx, { id }) => {
        ctx.lastDataChangeId = id;
      }),
    },
    'site.instanceStatusChanged': {
      actions: assign((ctx, { instanceStatus }) => {
        if (
          ctx.site?.contentSrc?.type === 'cloud-authoring' &&
          ctx.site.contentSrc.instance.type === 'branch'
        ) {
          ctx.site.contentSrc.instance.instanceStatus = instanceStatus;
        }
      }),
    },
  },
  states: {
    empty: {
      on: {
        [SET_APP_CLIENT_DATA]: {
          target: 'ready',
          actions: [
            assign((ctx, event) => {
              rootReducer(ctx.active, event);
              ctx.site = event.site;
              ctx.tokenData = event.tokenData;
              ctx.tokenStyles = event.tokenStyles;
            }),
            assign((ctx) => {
              if (
                ctx.site.env.type === 'development' &&
                ctx.site.contentSrc.type === 'current-env-server'
              ) {
                fixAppClientData({
                  appClientData: ctx.active,
                });
              }
            }),
            function saveFixedDataToLocal({
              site: { env, contentSrc },
              active,
            }) {
              if (
                // running local server
                env.type === 'development' &&
                // also getting our content from it
                contentSrc.type === 'current-env-server' &&
                // and we are in start mode
                env.appClientMeta.mode === 'start'
              ) {
                // in case the migrations changed any data, we want to immediately save it
                // otherwise we'd need to wait for the user to make a change
                // also this is async, but we don't need to `await` it
                submitDataForFileSave(active);
              }
            },
          ],
        },
      },
    },
    ready: {
      type: 'parallel',
      on: {
        'appClientData.prepForInstanceSwitch': {
          target: 'empty',
          actions: assign((ctx) => {
            ctx.lastCommittedDataChangeId = null;
            ctx.lastDataChangeId = null;
            ctx.site = null;
          }),
        },
      },
      entry: [
        assign((ctx) => {
          if (
            ctx.site.contentSrc.type === 'cloud-authoring' &&
            ctx.site.contentSrc.instance.type === 'branch'
          ) {
            const { instanceId } = ctx.site.contentSrc.instance;
            ctx.dataChangesBroadcastChannel = new DataChangesBroadcastChannel({
              siteId: ctx.site.meta.siteId,
              instanceId,
            });
          }
        }),
      ],
      exit: [
        assign((ctx) => {
          if (ctx.dataChangesBroadcastChannel) {
            ctx.dataChangesBroadcastChannel.close();
            ctx.dataChangesBroadcastChannel = undefined;
          }
        }),
      ],
      invoke: [
        {
          src: (ctx) => (sendEvent) => {
            const channel = ctx.dataChangesBroadcastChannel;
            if (!channel) return;
            channel.onmessage = (ev) => {
              const { data } = ev;
              switch (data.type) {
                case 'data-change': {
                  const { dataChange } = data;
                  sendEvent({
                    type: 'appClientData.externallyChanged',
                    subtype: 'changes',
                    changes: [dataChange],
                  });
                  break;
                }
                default: {
                  const _check: never = data.type;
                }
              }
            };
          },
        },
      ],
      states: {
        saver: {
          invoke: {
            src: (ctx) => (sendEvent) => {
              if (isUnitTesting) return;
              if (
                ctx.site.env.type !== 'development' ||
                ctx.site.contentSrc.type !== 'current-env-server'
              )
                return;
              const { websocketsEndpoint: appClientWebsocketsEndpoint } =
                ctx.site.env;
              if (!appClientWebsocketsEndpoint) return;
              const update = debounce(() => {
                try {
                  getAppClientData({
                    appClientUrl: ctx.site.env.url,
                  }).then(({ metaState, ...appClientData }) => {
                    sendEvent({
                      type: 'appClientData.externallyChanged',
                      subtype: 'fullData',
                      appClientData,
                    });
                  });
                } catch (e) {
                  sendEvent({
                    type: 'appClientData.saverError',
                    errorMsg: `Error with Local Dev WebSocket connection: ${e.message}`,
                  });
                }
              }, 1_500);
              const socket = new window.WebSocket(appClientWebsocketsEndpoint);

              socket.addEventListener('message', ({ data }) => {
                const theMsg: WebSocketMessages = JSON.parse(data ?? '{}');
                console.debug(
                  `app client websocket message: ${theMsg?.event}`,
                  theMsg,
                );
                switch (theMsg.event) {
                  case WS_EVENTS.RENDERER_CLIENT_RELOAD:
                  case WS_EVENTS.DESIGN_TOKENS_CHANGED:
                  case WS_EVENTS.APP_CLIENT_DATA_CHANGED: {
                    update();
                    break;
                  }
                }
              });

              socket.addEventListener('error', (error) => {
                console.error(error);
                sendEvent({
                  type: 'appClientData.saverError',
                  errorMsg: 'Error with Local Dev WebSocket connection',
                });
              });

              socket.addEventListener('close', (ev) => {
                sendEvent({
                  type: 'appClientData.saverError',
                  errorMsg: 'Local Dev WebSocket connection closed',
                });
              });

              return () => socket.close(1000, 'unmounting');
            },
          },
          initial: 'idle',
          on: {
            'appClientData.saverError': {
              target: '.error',
              actions: assign((ctx, event) => {
                ctx.saverError = event.errorMsg;
              }),
            },
            'appClientData.externallyChanged': {
              actions: [
                assign((ctx, event) => {
                  switch (event.subtype) {
                    case 'fullData': {
                      const { appClientData } = event;
                      const tokenData = convertTokenGroupToData(
                        appClientData.tokensSrc,
                      );
                      const tokenStyles = getTokenCollectionsCss({
                        tokenData,
                        minimize: true,
                      });
                      const setEvent: AppClientDataEvents = {
                        type: SET_APP_CLIENT_DATA,
                        payload: appClientData,
                        tokenData,
                        tokenStyles,
                      };
                      return {
                        ...alterActiveCtx({
                          ctx,
                          event: setEvent,
                          recipe: () => appClientData,
                        }),
                        tokenData,
                        tokenStyles,
                        saveStack: [],
                      };
                    }
                    case 'changes':
                      try {
                        const { changes } = event;
                        ctx.active = applyPatches(
                          ctx.active,
                          changes.flatMap((change) => change.patches),
                        );
                      } catch (error) {
                        console.error(error);
                        ctx.loaderError = loaderErrorMsg;
                        break;
                      }
                      break;
                    default: {
                      throw new Error(`Cannot handle`);
                    }
                  }
                }),
                // doing `assign` then `assign` in an `actions` causes `ctx` to be undefined FOR SOME REASON
                // turns out doing `assign` then `actions.assign` is the way to go
                actions.assign((ctx) => {
                  return updateTokensCtx({
                    ctx,
                    event: {
                      type: 'tokens.setAll',
                      tokensSrc: ctx.active.tokensSrc,
                    },
                  });
                }),
              ],
            },
          },
          states: {
            idle: {
              on: {
                'appClientData.saver.save': {
                  target: 'pushing',
                  cond: function isOkToSave(ctx) {
                    if (isUnitTesting) return false;
                    return possibleToSave(ctx.site);
                  },
                },
              },
              invoke: {
                id: 'saveStackWatcher',
                src: (ctx) => (sendEvent) => {
                  // heads up: `ctx` is only current when this state is entered - which is when `invoke.src` is ran
                  if (!possibleToSave(ctx.site)) {
                    return;
                  }
                  // this is the running instance of this machine
                  // This is the best way to watch for context changes and be able to send
                  // events into this machine that I could find. Other solutions all
                  // had different limitations. This only works because this machine (like
                  // most others) will only have 1 instance (service) running ever - i.e. singleton
                  const me = knapsackGlobal.appClientDataService;
                  const delay = 1_000;
                  let lastSaveStackLength = ctx.saveStack.length;
                  let timeoutId: NodeJS.Timeout;

                  const { unsubscribe } = me.subscribe((state) => {
                    const role = state.context.user?.roleForSite;
                    if (!role || !canRoleEdit(role)) {
                      // if they can't edit, then they can't save
                      return;
                    }
                    const saveStackTotal = state.context.saveStack.length;
                    if (saveStackTotal === 0) {
                      // nothing to save
                      return;
                    }
                    // not all changes to `state` need to be saved, so we check
                    // if the `saveStack` has grown since the last time we checked
                    if (saveStackTotal > lastSaveStackLength) {
                      lastSaveStackLength = saveStackTotal;
                      clearTimeout(timeoutId);
                      timeoutId = setTimeout(() => {
                        sendEvent('appClientData.saver.save');
                      }, delay);
                    }
                  });

                  return () => {
                    unsubscribe();
                  };
                },
              },
            },
            error: {
              exit: assign((ctx) => {
                ctx.saverError = null;
              }),
              on: {
                'appClientData.saverRetry': 'idle',
              },
            },
            pushing: {
              invoke: createInvokablePromise<{ changeIdsSaved: string[] }>({
                id: 'pusher',
                src: async (ctx) => {
                  const { saveStack, site } = ctx;
                  if (saveStack.length === 0) {
                    return { changeIdsSaved: [] };
                  }
                  const changeIdsSaved = saveStack.map(({ id }) => id);
                  if (
                    site.env.type === 'development' &&
                    site.contentSrc.type === 'current-env-server'
                  ) {
                    await submitDataForFileSave(ctx.active);
                    return { changeIdsSaved };
                  }
                  if (
                    site.contentSrc.type === 'cloud-authoring' &&
                    site.contentSrc.instance.type === 'branch'
                  ) {
                    await saveDataChanges({
                      changes: saveStack,
                      instanceId: site.contentSrc.instance.instanceId,
                    });
                    return { changeIdsSaved };
                  }
                },
                onDone: {
                  target: 'idle',
                  actions: assign((ctx, { data: { changeIdsSaved } }) => {
                    // remove every change in the save stack we just saved - some changes may come in mid-save
                    ctx.saveStack = ctx.saveStack.filter(
                      ({ id }) => !changeIdsSaved.includes(id),
                    );
                  }),
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  assign((ctx, event) => {
                    ctx.saverError = event.data.message;
                  }),
                  function userMessage(ctx, { data: error }) {
                    if (isUnitTesting) return;
                    import('@/utils/analytics').then(({ trackError }) => {
                      trackError(error);
                    });
                    createToast({
                      type: 'error',
                      title: 'Error saving data',
                      message: error.message,
                    });
                  },
                ],
              }),
            },
          },
        },
        data: {
          on: {
            // with `@ts-expect-error` this sometimes is an error and sometimes not; either way it's fine
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS may thinks `*` is not a valid event type, but Xstate allows it as a catch-all for any events that are not already handled
            '*': {
              cond: function isRightKindOfEvent(ctx, event) {
                // event types that start with these string will not cause any changes when sent into the `rootReducer` so let's save computation time and also allow other transitions in this state machine to hear those events b/c they won't even hear the event if it's heard here
                return !['appClientData', 'user', 'site'].some((type) =>
                  event.type.startsWith(`${type}.`),
                );
              },
              actions: [
                actions.assign(
                  (
                    ctx,
                    event: Exclude<
                      AppClientDataEvents,
                      {
                        type:
                          | `user.${string}`
                          | `appClientData.${string}`
                          | `site.${string}`;
                      }
                    >,
                  ) => {
                    if (isTokenEvent(event)) {
                      return updateTokensCtx({
                        event,
                        ctx,
                      });
                    }
                    if (event.type === 'dataDoctor.run') {
                      return alterActiveCtx({
                        ctx,
                        event,
                        recipe: (activeCtx) => {
                          fixAppClientData({ appClientData: activeCtx });
                        },
                      });
                    }
                    return alterActiveCtx({
                      ctx,
                      event,
                      recipe: (activeCtx) => {
                        rootReducer(activeCtx, event);
                        // any events that should have the Doctor run after
                        switch (event.type) {
                          case 'knapsack/patterns/DELETE_TEMPLATE':
                          case 'knapsack/DELETE_PATTERN':
                            fixAppClientData({ appClientData: activeCtx });
                        }
                      },
                    });
                  },
                ),
              ],
            },
          },
        },
      },
    },
  },
});
